// Generated by Framer (937e5b9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, Text, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {OvVlmS7W3: {hover: true}};

const cycleOrder = ["OvVlmS7W3"];

const variantClassNames = {OvVlmS7W3: "framer-v-nkt4ty"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 40, delay: 0, mass: 1, stiffness: 400, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, pareto, tap2, width, ...props}) => { return {...props, JUitaLg6Z: tap2 ?? props.JUitaLg6Z, lkWmgRPeR: pareto ?? props.lkWmgRPeR ?? "Pareto", XHevUj_3n: image ?? props.XHevUj_3n ?? {src: new URL("assets/512/SgK2B8b75UcKxU28iD06ZQ72oH4.png", import.meta.url).href, srcSet: `${new URL("assets/512/SgK2B8b75UcKxU28iD06ZQ72oH4.png", import.meta.url).href} 433w, ${new URL("assets/SgK2B8b75UcKxU28iD06ZQ72oH4.png", import.meta.url).href} 589w`}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;pareto?: string;image?: {src: string; srcSet?: string};tap2?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, lkWmgRPeR, XHevUj_3n, JUitaLg6Z, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "OvVlmS7W3", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1k5mns2 = activeVariantCallback(async (...args) => {
if (JUitaLg6Z) {
const res = await JUitaLg6Z(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-lyVuP", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={{webPageId: "Ldet_eLCG"}} openInNewTab={false}><Image {...restProps} as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 598, intrinsicWidth: 580, pixelHeight: 598, pixelWidth: 580, sizes: "min(290px, 100vw)", ...toResponsiveImage(XHevUj_3n)}} className={`${cx("framer-nkt4ty", className)} framer-14tvsdc`} data-framer-name={"Desktop"} data-highlight layoutDependency={layoutDependency} layoutId={"OvVlmS7W3"} onTap={onTap1k5mns2} ref={ref} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}} {...addPropertyOverrides({"OvVlmS7W3-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-sruduh"} layoutDependency={layoutDependency} layoutId={"sq8HhCRyb"} style={{backgroundColor: "rgb(221, 221, 221)", opacity: 0}} variants={{"OvVlmS7W3-hover": {background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, hsl(0, 0%, 0%) 100%)", opacity: 1}}}><Text __fromCanvasComponent className={"framer-17v7z5q"} fonts={["GF;Be Vietnam Pro-600"]} layoutDependency={layoutDependency} layoutId={"VEC5V5YoE"} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='direction: ltr; font-size: 0'><span style=''>Pareto</span><br></span></span>"} style={{"--framer-font-family": "\"Be Vietnam Pro\", sans-serif", "--framer-font-size": "24px", "--framer-font-style": "normal", "--framer-font-weight": 600, "--framer-letter-spacing": "-0.8px", "--framer-line-height": "1.2em", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-text-alignment": "start", "--framer-text-color": "rgb(255, 255, 255)", "--framer-text-decoration": "none", "--framer-text-transform": "none"}} text={lkWmgRPeR} verticalAlignment={"top"} withExternalLayout/></motion.div></Image></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-lyVuP [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-lyVuP .framer-14tvsdc { display: block; }", ".framer-lyVuP .framer-nkt4ty { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 290px; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 290px; will-change: transform; }", ".framer-lyVuP .framer-sruduh { align-content: flex-end; align-items: flex-end; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 290px; justify-content: flex-start; overflow: visible; padding: 30px 30px 10px 30px; position: relative; width: 1px; }", ".framer-lyVuP .framer-17v7z5q { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", ".framer-lyVuP .framer-v-nkt4ty .framer-nkt4ty { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-lyVuP .framer-nkt4ty, .framer-lyVuP .framer-sruduh { gap: 0px; } .framer-lyVuP .framer-nkt4ty > *, .framer-lyVuP .framer-sruduh > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-lyVuP .framer-nkt4ty > :first-child, .framer-lyVuP .framer-sruduh > :first-child { margin-left: 0px; } .framer-lyVuP .framer-nkt4ty > :last-child, .framer-lyVuP .framer-sruduh > :last-child { margin-right: 0px; } }", ".framer-lyVuP.framer-v-nkt4ty.hover .framer-sruduh { padding: 30px 30px 30px 30px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 290
 * @framerIntrinsicWidth 290
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"KFr6XmJPt":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"lkWmgRPeR":"pareto","XHevUj_3n":"image","JUitaLg6Z":"tap2"}
 */
const FramerljtWhkkDE: React.ComponentType<Props> = withCSS(Component, css, "framer-lyVuP") as typeof Component;
export default FramerljtWhkkDE;

FramerljtWhkkDE.displayName = "Project Card";

FramerljtWhkkDE.defaultProps = {height: 290, width: 290};

addPropertyControls(FramerljtWhkkDE, {lkWmgRPeR: {defaultValue: "Pareto", displayTextArea: false, title: "Pareto", type: ControlType.String}, XHevUj_3n: {__defaultAssetReference: "data:framer/asset-reference,SgK2B8b75UcKxU28iD06ZQ72oH4.png?originalFilename=LOGO-cropped.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, JUitaLg6Z: {title: "Tap 2", type: ControlType.EventHandler}} as any)

addFonts(FramerljtWhkkDE, [{family: "Be Vietnam Pro", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/ljtWhkkDE:default", url: "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HToIV8yT7wrcwap.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HToIV8yT7wrcwap.ttf", weight: "600"}])